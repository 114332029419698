import Alpine from 'alpinejs';
import cookieconsent from "./cookieconsent-config";

window.Alpine = Alpine;
window._      = require('lodash');
window.axios  = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Alpine.start();


$(function() {

    if ($('#alert-status').length) {
       setTimeout(function () { $('#alert-status').addClass('hidden');}, 4000);
    }
});
